<template>
	<div class="home">
        <nav class="nav_sech">
            <el-button class="el_btn" @click="tableDataAdd" type="primary">新增</el-button>
        </nav>
        <div class="inte"></div>
        <main class="el_table" style="margin:15px">
            <el-table
            :data="tableData"
            style="width:100%;margin-bottom: 20px"
            row-key="id"
            border
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                    prop="title"
                    label="菜单名称"
                    align="left"
                    width="220">
                </el-table-column>
                <el-table-column
                    label="菜单分类"
                    align="center" 
                    width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.iclass==1">政务公开</span>
                        <span v-if="scope.row.iclass==2">资讯</span>
                        <span v-if="scope.row.iclass==3">人事代理</span>
                        <span v-if="scope.row.iclass==4">劳务派遣</span>
                        <span v-if="scope.row.iclass==5">服务页面顶部介绍</span>
                        <span v-if="scope.row.iclass==6">隐私政策</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="位置"
                        align="center"
                        width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.location==0">标签切换/默认</span>
                        <span v-if="scope.row.location==1">位置1</span>
                        <span v-if="scope.row.location==2">位置2</span>
                        <span v-if="scope.row.location==3">位置3</span>
                    </template>
                </el-table-column>
                <el-table-column 
                    prop="msort"
                    label="排序" 
                    align="center" 
                    width="200">
                </el-table-column>
                <el-table-column
                    label="状态" 
                    align="center" 
                    width="170">
                    <template slot-scope="scope">
                        <span style="color: blue;" v-if="scope.row.status==0">有效</span>
                        <span style="color: red;" v-if="scope.row.status==1">关闭</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button
                                size="medium "
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </main>
        <el-dialog
                :title="diaTitle"
                :visible.sync="dialogVisible"
                width="38%">
                <template>
                    <el-form ref="addEditinfo" size="medium" :model="addEditinfo" label-width="100px">
                        <el-form-item label="菜单名称">
                            <el-input class="el_input" v-model="addEditinfo.title" placeholder="请输入菜单名称"></el-input>
                        </el-form-item>
                        <el-form-item label="菜单分类">
                            <el-select class="el_input" v-model="addEditinfo.iclass" placeholder="请选择类型" clearable>
                                <el-option
                                        v-for="item in iclassoptions" :key="item.value"
                                        :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="菜单位置">
                            <el-select class="el_input" v-model="addEditinfo.location" placeholder="请选择位置" clearable>
                                <el-option
                                        v-for="item in locationoptions" :key="item.value"
                                        :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select class="el_input" v-model="addEditinfo.status" placeholder="请选择" clearable>
                                <el-option
                                        v-for="item in statusoptions":key="item.value"
                                        :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="图片地址">
                            <el-input class="el_input" v-model="addEditinfo.iconurl" placeholder="请输入图片地址"></el-input>
                        </el-form-item>
                        <el-form-item label="显示排序">
                            <el-input class="el_input" v-model="addEditinfo.msort" type="number" min="0" placeholder="请输入排序">
                            </el-input>
                        </el-form-item>
                    </el-form>
                </template>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="postAddData()">确 定</el-button>
                </span>
        </el-dialog>
	</div>
</template>

<script>
export default {
	name: "Web_topNav",
	data() {
		return {
            navSech:{
                iclass:-1
            },
            tableData:[],
            iclassoptions: [{
                value: 1,
                label: '政务公开'
            }, {
                value: 2,
                label: '资讯'
            }, {
                value: 3,
                label: '人事代理'
            }, {
                value: 4,
                label: '劳务派遣'
            }, {
                value: 5,
                label: '服务页面顶部介绍'
            }, {
                value: 6,
                label: '隐私政策'
            }],
            locationoptions: [{
                value: 0,
                label: '标签切换/默认'
            }, {
                value: 1,
                label: '位置1'
            }, {
                value: 2,
                label: '位置2'
            }, {
                value: 3,
                label: '位置3'
            }],
            statusoptions:[
                {
                    value: 0,
                    label: '有效'
                }, {
                    value: 1,
                    label: '关闭'
                }
            ],
            dialogVisible:false,
            diaTitle:'',
            addEditinfo:{
                location:0,
                status:0
            },

            addUpPermissions: [],
            props:{
                lazy: true,
                value:'id',
                checkStrictly:true,
                label:'navName',
                lazyLoad: this.menusfather
            }
		};
	},
	created() {
        this.getNavlist();   
    },
	methods: {
        getNavlist:function(){
            var _self=this;
            axios.get(this.API.news.menus,{
                headers:{
                    token:this.Util.getjwtToken(),
                },
                params:this.navSech
                }).then(function (res) {
                    if(res.data.success){
                        _self.tableData=res.data.data;
                    }else{
                        _self.tableData=[] 
                    }
            })
        },
        //查询
        navSechlist:function(){
            this.getNavlist()
        },
        //添加
        tableDataAdd:function(){
            this.addEditinfo={
                navpid:'0',
                navIsHide:0
            };
            this.diaTitle="新增导航栏";
            this.dialogVisible=true;
        },
        changeProps:function(arrid){
            this.addEditinfo.navpid=arrid[arrid.length-1];
        },
        //根据父id获取类型菜单
        menusfather:function(node, resolve){
            var _self=this;
            var pid='0';
            if(node.data){
                pid = node.data.id
            }
            axios.get(_self.API.navmenu.list,{
                headers:{
                    token:_self.Util.getjwtToken(),
                },
                params:{
                    type:-1,
                    pid:pid
                }
                }).then(function (pores) {
                    if(pores.data.success){
                        if(pores.data.data&&pores.data.data.length>0){
                            resolve(pores.data.data)
                        }else{
                            resolve([])
                        }
                    }else{
                        resolve([]) 
                    }
                });
        },
        //编辑
        handleEdit:function(index,row){
            this.diaTitle="编辑";
            row.navMenuType=row.navMenuType+'';
            this.addEditinfo=JSON.parse(JSON.stringify(row));
            this.dialogVisible=true;
        },
        //隐藏
        handleProhibit:function(index,row){
            if(row.navIsHide==0){
                row.navIsHide=1 
            }else{
                row.navIsHide=0
            }
            this.isSuccessgetData(row)
        },
        //提交
        postAddData:function(){
            if(this.Util.isBlank(this.addEditinfo.title)){
                this.$message.warning('请输入菜单名称！');
                return
            }
            if(this.Util.isBlank(this.addEditinfo.iclass)){
                this.$message.warning('请选择栏目类型！');
                return
            }
            console.log("this.addEditinfo.location:"+this.addEditinfo.location);
            if(typeof(this.addEditinfo.location)=="undefined" || this.addEditinfo.location==null){
                this.$message.warning('请选择菜单位置！');
                return
            }
            // if(this.Util.isBlank(this.addEditinfo.navsort)){
            //     this.$message.warning('请输入显示排序！');
            //     return
            // }
            this.isSuccessgetData(this.addEditinfo)
        },
        isSuccessgetData:function(DataInfo){
            var _this=this;
            axios({
                url:this.API.news.menus_editor,
                method: 'post',
                data:DataInfo,
                headers:{
                    token:this.Util.getjwtToken(),
                },
            }).then(function (res) {
                console.log(res)
                if(res.data.success){
                    _this.dialogVisible=false;
                    _this.addEditinfo={
                        location:0,
                        status:0
                    };
                    _this.getNavlist();
                }else{

                }
            })
        },
        //删除
        handleDelete:function(index,row){
            var _self=this;
                this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _self.handleDeleteData(index,row);
            })
        },
        handleDeleteData:function(index,row){
            var _this=this;
            axios({
                    url:this.API.news.menus_delete,
                    method: 'delete',
                    params:{
                        id:row.id,
                    },
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                }).then((res)=>{
                    if(res.data.success){
                        _this.$message.success('删除成功!');
                        _this.getNavlist();
                    }else{

                }
            })
        }    
	}
};
</script>
<style lang="scss" scoped>

</style>